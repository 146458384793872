import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
  return async () =>
    await keycloak
      .init({
        config: {
          url: environment.keycloak.url,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId,
        },
        loadUserProfileAtStartUp: true,
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false,
          silentCheckSsoFallback: false,
          // silentCheckSsoRedirectUri: window.location.origin + environment.keycloak.silentCheckSsoRedirectUri,
          enableLogging: true,
        },
        shouldAddToken: (request) => {
          // const { method, url } = request;

          // const isGetRequest = 'GET' === method.toUpperCase();
          // const acceptablePaths = ['/assets', '/clients/public'];
          // const isAcceptablePathMatch = url.some((path) => url.includes(path));

          // return !(isGetRequest && isAcceptablePathMatch);
          return true;
        },
      })
      .then(() => {
        keycloak.keycloakEvents$.subscribe({
          next: (e) => {
            if (e.type == KeycloakEventType.OnTokenExpired) {
              keycloak.updateToken(20);
            }
          },
        });

        return true;
      });
}
