import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  static download(response: HttpResponse<Blob>, overrideUrl?: string): void {
    const contentDisposition = response.headers.get('content-disposition');
    const url = overrideUrl || response.url;

    const filename = !!contentDisposition
      ? contentDisposition.match(/filename="(.*?)"/)[1]
      : `${url.substr(url.lastIndexOf('/') + 1)}`;

    saveAs(response.body, filename);
  }

  constructor(private httpClient: HttpClient) {}

  tryDownloadAndSave(url: string): Observable<void> {
    return new Observable((observer: Subscriber<void>) => {
      this.httpClient
        .get(url, {
          responseType: 'blob',
          observe: 'response',
        })
        .subscribe({
          complete: () => observer.complete(),
          error: (err) => observer.error(err),
          next: (response: HttpResponse<Blob>) => DownloadService.download(response, url),
        });
    });
  }
}
