import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ApiConfiguration } from '../api/api-configuration';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private apiConfig: ApiConfiguration, iconRegistry: MatIconRegistry) {
    iconRegistry.registerFontClassAlias('rounded', 'material-icons-round');
  }

  ngOnInit(): void {
    this.apiConfig.rootUrl = environment.apiUrl;
  }
}
