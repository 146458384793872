import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, filter } from 'rxjs';
import { ExportControlService } from '../modules/shared/modules/list-page/services/export-control.service';
import { DownloadService } from '../modules/shared/services/download.service';

@Injectable()
export class ExportInterceptor implements HttpInterceptor {
  constructor(
    private exportControlService: ExportControlService,
    private toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
  ) {}

  intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    const languageHeaderReq = req.clone({
      ...req,
      headers: req.headers.set('Accept-Language', this.i18NextService.language),
    });

    if (req.headers.get('Accept') === 'application/vnd.ms-excel' || req.headers.get('Accept') === 'application/pdf') {
      next
        .handle(languageHeaderReq)
        .pipe(filter((e) => e instanceof HttpResponse))
        .subscribe({
          next: (response: HttpResponse<Blob>) => {
            DownloadService.download(response, req.url);
            this.exportControlService.setExporting(false);
            this.toastr.success(this.i18NextService.t('success.export', { count: 1 }));
          },
          error: () => {
            this.exportControlService.setExporting(false);
            this.toastr.error(this.i18NextService.t('errors.export', { count: 1 }));
          },
        });

      return EMPTY;
    }

    return next.handle(languageHeaderReq);
  }
}
