import { Resource } from 'i18next';
import enGBCommon from './locales/en.json';
import ptPTCommon from './locales/pt.json';

export enum ELanguages {
  pt = 'pt',
  en = 'en',
}

export enum ENamespaces {
  Common = 'common',
}

export const ptPT = {
  [ENamespaces.Common]: ptPTCommon,
};

export const enGB = {
  [ENamespaces.Common]: enGBCommon,
};

export const languages = [ELanguages.pt, ELanguages.en];

export const namespaces = [ENamespaces.Common];

export const resources: Resource = {
  [ELanguages.pt]: ptPT,
  [ELanguages.en]: enGB,
};
