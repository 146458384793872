import { APP_BASE_HREF, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputMaskModule } from '@ngneat/input-mask';
import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from 'i18n/config';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { ToastrModule } from 'ngx-toastr';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { initializeKeycloak } from '../app/utilities/initializeKeyCloak';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExportInterceptor } from './interceptors/export-interceptor.interceptor';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxWebstorageModule.forRoot(),
    I18NextModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    MatSnackBarModule,
    ToastrModule.forRoot({
      maxOpened: 3,
      // preventDuplicates: true,
      progressAnimation: 'decreasing',
      progressBar: true,
    }),
    InputMaskModule,
    HighlightModule,
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExportInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    I18N_PROVIDERS,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
