import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import LanguageDetector from 'i18next-browser-languagedetector';

import { defaultInterpolationFormat, I18NextModule, I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

import { environment } from 'projects/rcd/src/environments/environment';
import { ELanguages, ENamespaces, languages, namespaces, resources } from './helpers';

export function appInit(i18next: ITranslationService) {
  return () => {
    i18next.use(LanguageDetector).init({
      fallbackLng: ELanguages.pt,
      defaultNS: ENamespaces.Common,
      interpolation: { format: I18NextModule.interpolationFormat(defaultInterpolationFormat) },
      debug: environment.i18nextDebug,
      detection: {
        order: ['localStorage', 'sessionStorage', 'querystring', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      },
      ns: namespaces,
      resources,
      supportedLngs: languages,
    });
  };
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
